@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
 
}

::selection{
  background-color: #425542;
  color: white;
}

:root {
  --color-one: rgb(78, 55, 39);
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: 'Rubik', sans-serif;
  --font-serif: 'Cardo', serif;
  --bg: #FEF5DF;
}

.img-first {
  width: 100%;
  outline: 2px solid white;
  outline-offset: -14px;
}

.display-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.text-home {
  font-family: "Cormorant Garamond", serif;
  font-size: 35px;
  font-weight: 600;
  padding: 30px;
  justify-content: center;
  color: #fff;
}

.text-home p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.text-home h1 {
  font-size: 2.9rem;
}

.text-home h1 span {
  color: #fff;
  font-weight: 500;
  font-style: italic;
}

.text-home a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-top: 60px;
}

.text-home a::after {
  content: " ";
  display: block;
  height: 2px;
  width: 130px;
  position: relative;
  left: 12%;
  margin-top: -20px;
  background-color: #fff;
  transition: all 1s;
}

.nav-link-button{
  padding: 10px;
  letter-spacing: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: #588157;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 500ms;
}

.nav-link-button:hover{
  border: 1px solid #588157;
  color: #588157;
  background-color: white;
  transition: all 500ms;
}
H5{
  text-align: center;
  letter-spacing:2pt;
}
.hh{
  text-align: center;
}
.logoja{
  width: 100px;
  cursor: pointer;
}
.text-home :hover::after{
  width: 150px; 
  background-color: #588157;
  transition: all 1s;
}

.text-home img {
  width: 150px;
  position: relative;
  opacity: 40%;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.book {
  width: 100%;
  height: 20vh;
  color: white;
  background-color: #f1f1f1;
}

.book .input {
  background-color: white;
  color: black;
  font-family: "Cormorant Garamond", serif;
}

.book .search {
  background-color: #588157;
  padding: 20px;
}

.inp-home {
  width: 100%;
  padding: 15px;
  border: 1px solid rgb(88 129 87);
  outline: none;
  background-color: white;
  margin-top: 20px;
  font-size: 18px;
  border-radius: 10px;
}

::placeholder {
  color: rgb(0, 0, 0);
}

.book .search {
  width: 20%;
}

.box {
  border: 1px solid rgb(217 217 217);
  padding: 5px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btn-book-home {
  width: 100%;
  height: 100%;
  font-size: 20px;
  background: #588157;
  color: white;
  border: 1px solid #588157;
  transition: all 500ms;
}

.btn-book-home:hover{
  border: #588157 1px solid;
  color: #588157;
  background-color: transparent;
  transition: all 500ms;
}

.box1 {
  border: 0;
  padding: 0;
}

.star-icons {
  width: 100px;
}

.about-us-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-part {
  margin-top: 30px;
  font-family: "Cormorant Garamond", serif;
}

.about-us-imgs img {
  height: 350px;
}

.f-img {
  margin-top: 60px;
  outline: 2px solid white;
  outline-offset: -14px;
}

.info-img {
  overflow: hidden;
}


.s-img {
  margin-bottom: 60px;
  margin-left: 30px;
  outline: 2px solid white;
  outline-offset: -14px;
  z-index: -3;
}

.about-left-part-home {
  padding: 50px;
}

.tel-div {
  align-items: center;
  display: flex;
}

.num-text {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #588157;
}

.margin-text {
  margin-left: 10px;
}

.tel-div p {
  margin: 0;
}

.th-img {
  width: 150px;
  position: absolute;
  z-index: 99;
  animation: rotate 6s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.reserve-text {
  font-family: "Poppins", sans-serif;
}


.phone-icon {
  width: 50px;
}


.body {
  display: grid;
  place-items: center;
  margin-top: 40px;
  font-family: 'Roboto';
}

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 1024px;
  margin: 0 auto;
  font-family: 'Roboto';
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
  

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(3, 1fr);
  }
  .map-sec{
    padding: 40px
  }
}
}

.cardd {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
}

@media (min-width: 600px) {
  .cardd {
    height: 350px;
  }
}

.cardd:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: center;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}

.cardd:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.320) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.540) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.790) 100%);
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

.cardd:nth-child(1):before {
  background-image: url("./imgs/Sandart Room.jpg");
}
.im{
  height: 270.66px;
}
.cardd:nth-child(2):before {
  background-image: url("./imgs/twen rom.jpg");
}

.cardd:nth-child(3):before {
  background-image: url("./imgs/Apartament.jpg");
}
.cardd:nth-child(4):before {
  background-image: url("./imgs/Quin Room (2).jpg");
}
.cardd:nth-child(5):before {
  background-image: url("./imgs/Apartament.jpg");
}
.cardd:nth-child(6):before {
  background-image: url("./imgs/Egzekutiv Room.jpg");
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
}

.content>*+* {
  margin-top: 1rem;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btnn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  border: 1px solid white;
}

  .cardd:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));
  }

  .content>*:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }

  .cardd:hover,
  .cardd:focus-within {
    align-items: center;
  }

  .cardd:hover:before,
  .cardd:focus-within:before {
    transform: translateY(-4%);
  }

  .cardd:hover:after,
  .cardd:focus-within:after {
    transform: translateY(-50%);
  }

  .cardd:hover .content,
  .cardd:focus-within .content {
    transform: translateY(0);
  }

  .cardd:hover .content>*:not(.title),
  .cardd:focus-within .content>*:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .cardd:focus-within:before,
  .cardd:focus-within:after,
  .cardd:focus-within .content,
  .cardd:focus-within .content>*:not(.title) {
    transition-duration: 0s;
  }


.title-about-us {
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
}

.title-about-us span {
  color: #588157;
}

.center-text-book {
  font-family: "Cormorant Garamond", serif;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.center-text-book h1 span {
  color: #588157;
}

.line-gold {
  width: 300px;
  height: 1px;
  margin: 0;
  margin-top: 15px;
  background-color: #588157;
}

.center-text-book p {
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  color: grey;
}

.center-text-book h1 {
  font-size: 2.9rem;
}

.width-rec {
  max-width: 400px;
}

.weather-sec h1 {
  font-size: 50px;
  font-family: 'Forum', cursive;
  color: #588157;
}

.weather-sec p {
  font-size: 30px;
  font-family: 'Forum', cursive;
}

.weather-sec:nth-child(2) {
  border-left: 1px solid rgb(209, 209, 209);
  padding-left: 30px;
}


.weather-sec:nth-child(3) {
  border-left: 1px solid rgb(209, 209, 209);
  padding-left: 30px;
}

.activities-txt h1 {
  font-family: 'Forum', cursive;
  font-size: 70px;
  color: #588157;
  letter-spacing: 1px;
}

.rect h1 {
  font-size: 25px;
  font-family: 'Forum', cursive;
  margin-top: 20px;
}

.rect p {
  font-size: 16px;
  color: rgb(188, 188, 188);
}

.rect img {
  width: 50px;
  height: 50px;
}

.banner-srvc {
  background: linear-gradient(rgba(0, 0, 0, 0.434), rgba(0, 0, 0, 0.27)), url("./imgs/backi.JPG");
  background-attachment: fixed;
  background-size: cover;
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  outline: 2px solid white;
  outline-offset: -14px;
  font-family: 'Forum', cursive;
  text-align: center;
}

.banner-srvc h1 {
  font-size: 60px;
}

.banner-srvc p {
  font-size: 25px;
  line-height: 25px;
}

.banner-srvc button {
  padding: 15px;
  background-color: #588157;
  letter-spacing: 3px;
  color: white;
  border: 0;
  font-size: 14px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid transparent;
  transition: all 500ms;
}

.banner-srvc button:hover{
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: all 500ms;
}

.banner-srvc button::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #588157;
  position: relative;
  top: 95%;
  display: block;
  transition: all 500ms;
}

.post-imgs img {
  width: 100%;
}

.post-imgs {
  margin-top: 30px;
}


.post-texts {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Forum', cursive;
}

.post-texts h4 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 3px;
  font-size: 14px;
  color: grey;
}

.post-texts h1 {
  font-size: 45px;
}

.post-imgs div:nth-child(2) {
  margin-top: 30px;
}

.second-title-post span {
  color:#588157;
}

.title-post::before {
  content: "";
  width: 2px;
  position: relative;
  height: 50px;
  left: 50%;
  margin-bottom: 30px;
  background-color: #CCC1BD;
  display: block;
}

.gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 400px;
  /* Adjust the width as needed */
  height: 400px;
  /* Adjust the height as needed */
}

.rectangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.hovered-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-titles {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.gallery-title {
  cursor: pointer;
}


.margin-top-exclusive {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-exclusive-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 500px;
  border-top: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
}

.welcome-exclusive-card h1 {
  font-size: 3.6rem;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  color: #9c9284;
  letter-spacing: 2px;
}

.welcome-exclusive-card p {
  font-family: 'Poppins', sans-serif;
  color: gray;
}

#spec {
  transform: rotate(180deg);
}

.exclusive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 600px;
  margin-top: 40px;
}

.remove-round {
  border-radius: 0;
}

.remove-round div h6 {
  color: #588157;
  font-size: 14px;
}

.remove-round div p {
  color: rgb(66, 66, 66);
  margin-top: -10px;
}

.remove-round .card-body {
  padding: 30px;
}

.remove-round button {
  padding: 10px;
  letter-spacing: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: #588157;
  color: #fff;
  margin-top: 15px;
  border: 1px solid transparent;
  transition: all 500ms;
}

.remove-round button:hover{
  border: 1px solid #588157;
  color: #588157;
  background-color: white;
  transition: all 500ms;
}

.welcome-exclusive-card button {
  padding: 10px 15px;
  letter-spacing: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid black;
}

.remove-round .card-body h4 {
  font-family: "Cormorant Garamond", serif;
}

.rec-map {
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  transition: all 200ms;
}

.rec-map:hover {
  transform: translateY(-4px);
  transition: all 200ms;
}

.rec-map h1 {
  font-size: 18px;
  margin: 0 10px;
  font-family: 'Forum', cursive;
}

.rec-map img {
  width: 40px;
  padding-right: 10px;
  border-right: 1px solid #9c9284;
}

.map-sec {
  display: flex;
  align-items: center;
}


.contact-name {
  font-family: "Cormorant Garamond", serif;
  font-size: 3.9rem;
  color: #588157;
}

.footer-home {
  background-color: #588157;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  color: white;
  box-shadow: 0px 0px 5px 0px rgba(233, 233, 233, 0.75);
  padding-top: 50px;}


.imgs-footer {
  display: flex;
  margin-top: 20px;
}

.imgs-footer img {
  margin-right: 10px;
  width: 40px;
  padding: 7px;
  cursor: pointer;
  background-color: #ffffff;
}

.text-style div h1 {
  font-size: 20px;
  color: #588157;
}

.text-style p,
li {
  color: rgb(255, 255, 255);
  list-style: none;
}

.text-style ul{
  margin: 0;
  padding-left: 0;
}


.text-style ul li{
  margin: 10px 0;
  cursor: pointer;
}


.cr-down {
  background-color: #2e382e;
  text-align: center;
  color: white;
  padding: 10px 0;
  margin-top: 50px;
}

.cr-down h1 {

  font-size: 15px;
}

#title-styles h1{
  font-size: 25px;
  background-color: #588157;
  color: white;
}

.hotel-cards div div{
  margin: 0;
  border-radius: 0;
}

.hotel-cards div div > img{
  border-radius: 0;
}

.hotel-icons{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
}

.hotel-icons ul li{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
  margin-top: 10px;
}

.hotel-icons ul li img{
  width: 30px;
}

.hotel-icons ul{
margin-top: 20px;
padding: 0;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}


.hotel-icons ul li p{
  margin-bottom: 0;
  margin-left: 10px;
}

.card-hotel-customize{
  padding-top: 30px;
  padding-left: 30px;
  border: 0;
  padding-right: 30px;
}

.card-hotel-customize h2{
  font-family: "Cormorant Garamond", serif;
}

.card-hotel-customize h6 span{
  color: #588157;
  font-size: 30px;
  font-family: 'Forum', cursive;
}

.card-hotel-customize p{
  color: grey;
  font-size: 15px;
}

.hotel-buttons .first-button-hotel{
  background-color: transparent;
  color: gray;
  border: 0;
  letter-spacing: 3px;
  padding-bottom: 1px;
  border-bottom: 1px solid grey;
  font-size: 15px;
}
a{
  color: #588157;
  text-decoration: none;
}
a:hover{
  color: black;
}
.hotel-buttons .second-button-hotel{
  background-color: #588157;
  padding: 10px;
  border: 0;
  color: white;
  letter-spacing: 3px;
  font-size: 15px;
  border: 1px solid transparent;
}

.hotel-buttons .second-button-hotel:hover{
  color: #588157;
  background-color: white;
  border: 1px solid #588157;
  transition: all 500ms;
}

.bg-rooms-entry{
  background: linear-gradient(rgba(0, 0, 0, 0.338),rgba(0, 0, 0, 0.269)) , url("./imgs/IMG_0768.jpg");
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 350px;
  display: flex;
  padding: 80px;
  color: white;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.bg-rooms-entry img{
  width: 100px;
}

.bg-rooms-entry h1 , p{
  margin: 0;
  padding: 0;
}

.bg-rooms-entry h1{
  font-size: 60px;
  font-style: italic;
  font-family: "Cormorant Garamond", serif;
}

.bg-rooms-entry p{
  letter-spacing: 4px;
}

.checks{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.checks h4{
  margin-left: 10px;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

#change-direct{
  display: flex;
  align-items: center;
}

.left-detail-part img{
  width: 70px;
}


.checks img{
  width: 30px;
}

.checking-div{
  margin-top: 30px;
}
.details-full{
  margin-top: 40px;
}

.div-of-icons-details ul li{
  display: flex;
  margin: 20px 0;
  align-items: center;
}

.div-of-icons-details img{
  width: 40px;
}

.div-of-icons-details ul li p{
  margin-left: 10px;
  color: #2e382e;
}

.left-detail-part h6{
  letter-spacing: 3px;
  color: gray;
  font-weight: 400;
}

.left-detail-part h1{
  font-size: 50px;
  font-family: "Cormorant Garamond", serif;
}

.left-detail-part h2{
  font-family: "Cormorant Garamond", serif; 
}

.left-detail-part p{
  color: grey;
}

.checking-div div h2{
  font-family: "Cormorant Garamond", serif;
}

.checks h4{
  color: gray;
  font-weight: 400;
}

.cl-anime{
  font-family: "Cormorant Garamond", serif;
}

.amenities-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.swiper {
  width: 100%;
  height: 700px;

}
.offer{
  object-fit: cover;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  outline: 2px solid white;
  /* outline-offset: -14px; */
  outline-offset: -5px;
}

.card-form button{
  padding: 10px;
  letter-spacing: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: #588157;
  color: #fff;
  margin-top: 25px;
  border: 1px solid transparent;
  transition: all 500ms;
}

.card-form button:hover{
  border: 1px solid #588157;
  color: #588157;
  background-color: white;
  transition: all 500ms;
}


#social-medias{
  justify-content: flex-end;
}

.swiper-slide {
  width: 60%;
}

.swiper-slide:nth-child(1n) {
  width: 60%;
}
.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 60%;
}

.swiper-slide:nth-child(4n) {
  width: 60%;
}
.swiper-pagination-bullet-active {
  background-color: #ff0000; /* Change the background color of the active bullet */
}

@media screen  and (max-width:1191px){
  .text-home a::after{
    content: '';
    display: none;
  }
  .br1{
    display: none;
  }
  .swiper{
    margin-top: 20px;
  }
}

.card-img-top{
  height: 270px;
}

@media screen  and (max-width:991px){
.weather-sec:nth-child(2) , .weather-sec:nth-child(3){
  border: 0;
  padding: 0;
  margin-top: 30px;
}

.rect{
  margin: 20px 0;
}
.img-first{
  width: 600px;
}
.swiper{
  height: auto;
}
.swiper-slide img{
  width: 100vw;
  height: 300px;
}


.hover03{
  display: flex;
  justify-content: center;
}
.book .input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checking-div div h2{
  margin-bottom: 20px;
}

.checking-div div{
  margin: 10px 0;
}

.amenities-div{
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}

.div-of-icons-details{
  display: flex;
  flex-wrap: wrap;
}

.hotel-icons{
  flex-wrap: wrap;
}

.book{
  height: auto;
}
#remove-flex{
  display: block;
}

#width-par{
  text-align: center;
  width: 700px;
}
.exclusive{
  height: auto;
  padding: 0;
  justify-content: center;
}

.margin-top-exclusive div{
  margin: 10px 0;
}

.contact-top{
  margin-top: 10px;
}

#social-medias{
  justify-content: flex-start;
}
#margin-between div{
  margin: 20px 0;
}
#margin-of-post{
  margin-top: 30px;
}
}

@media screen and (max-width:970px) {
  .rect{
    text-align: center;
  }
  .activities-txt{
    text-align: center;
  }
  .map-sec{
    padding: 10px
  }
}




@media screen  and (max-width:765px){
.activities-txt h1{
  font-size: 70px;
}
.img-first{
  width: 500px;
}
.text-home h1{
  text-align: left;
  font-size: 1.5rem;
  margin-left: 50px;
}
.btn-book-home{
  height: 50px;
}
.about-left-part-home{
  padding: 20px;
}
#width-par{
  width: auto;
  padding: 0 40px;
}
.br2{
  display: none;
}
.whats{
  width: 45px;
}

#first-width{
  width: auto;
  text-align: center;
  padding: 0 20px;
}
.banner-srvc{
  height: 800px;
}

.f-img , .s-img , .th-img{
  outline-offset: 0;

}
}

@media screen  and (max-width:530px){
.img-first{
  width: 400px;
}

.banner-srvc p{
  font-size: 16px;
}
.card-form button{
  margin-top: 40px;
}
.center-text-book h1{
  font-size: 2.5rem;
}
.post-texts h1{
  font-size: 38px;
}
.contact-name{
  font-size: 3.5rem;
}
}

@media screen  and (max-width:430px){

.img-first{
  width: 350px;
}

.title-about-us{
  font-size: 2.2rem;
}
.num-text{
  font-size: 19px;
}
}

@media screen and (max-width:1004px) {
  .card-hotel-customize{
    padding: 15px;
  }
  #flex-direction-hotel{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .bg-rooms-entry{
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .unique{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }
}

.inputt {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 0.7em;
	&+.inputt {
		margin-top: 1.5rem;
	}
}

.input-label {
	color: #8597a3;
	position: absolute;
	top: 1.5rem;
	transition: .25s ease;
}

.input-field {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #979797; 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: #588157;
		&+.input-label {
			color:  #588157;
			transform: translateY(-1.5rem);
		}
	}
}

.navbar{
  z-index: 9999;
}

.navbar-toggler:focus{
  box-shadow: 0 0 0 0.10rem;
}


#gray{
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 50px 0;
}

.navbar-nav .nav-item{
  margin:5px 5px;
}


.dp-none{
  display: none;
}

@media screen  and (max-width:768px){
  #change-direct{
    align-items: flex-start;
  }
  .nav-link{
    font-weight: 500;
  }
  .navbar-nav .nav-item{
    margin: 7px;
  }
  .dp-none{
    display: block;
  }
  .logoja{
    width: 50px;
  }
  .activities-txt h1{
    font-size: 50px;
  }

  #first-width{
    font-size: 50px;
  }
  .welcome-exclusive-card h1{
    font-size: 3.2rem;
  }
  .navbar{
    padding: 20px;
  }
  .navbar-nav .nav-item{
    margin: 10px;
  }
  .card-form{
    padding: 0 20px;
  }
}


.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #588157;
  color: white;
  border: none;
  padding: 10px;
  border-radius:100%;
  font-size: 16px;
  cursor: pointer;
  transition: all 500ms;
  z-index: 999;
}

.scroll-to-top-button:hover {
  background-color: #2a382a;
  transition: all 500ms;
}


.text-overlay {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;

}

.text-overlay p, .text-overlay h1, .text-overlay a {
  margin-bottom: 10px;
}

.custom-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-modal {
  position: relative;
  max-width: 400px;
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  z-index: 1; /* Ensure modal is above overlay */
}

.modal-content {
  padding: 20px;
}

.modal-content h1 {
  margin-bottom: 10px;
  color: #037d44;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #037d44;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reservation-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; /* Set your desired background color */
  padding: 50px;
}

.modali{
  background-color: transparent;
  border: none;
}
/* Styles for the overlay */
.reservation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Set your desired overlay color and opacity */
  z-index: 1000;
}

.modal-content {
  padding: 20px;
}

.modal-content h2 {
  margin-bottom: 10px;
}

/* Additional form styles as needed */
.form-group {
  margin-bottom: 10px;
}
.nisi{
  margin-top: 30px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.form-group button {
  padding: 10px 20px;
  background-color: #037d44;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.butonat{
  display: flex;
  justify-content: space-between;
}
.wi{
  width: 80px;
  background-color: red;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.w{
width: 150px;
padding: 10px 20px;
background-color: #037d44;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
}
.form-group input{
border: none;
background-color: #e7e7e7;
}
.reservation-overlay {
  z-index: 1000; /* Adjust this value to be higher than other elements */
}

.modali {
  z-index: 1001; /* Make it slightly higher than the overlay */
}

/* Styles for Thank You Modal */
.custom-overlay {
  z-index: 1000; /* Adjust this value to be higher than other elements */
}

.custom-modal {
  z-index: 1001; /* Make it slightly higher than the overlay */
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #29a71a; /* WhatsApp green color */
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
@media screen  and (max-width:765px){
  .whatsapp-button {
    padding: 5px;
    bottom: 25px;
  }
}
.hi{
  text-align: center;
  margin-top: 20px;
}



.dropdown-menu{
  min-width: 250px;
  border: none;
  box-shadow: 0px 3px 5px -1px #ccc;
  padding: 10px;
}

.dropdown-menu a {
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}


 .dropdown-menu a:hover {
  padding-left: 10px;
  border-left: 2px solid #588157;
  transition: all 0.3s ease;
}
.dropdown-menu a:focus {
background-color: #58815783;

}
/* .nav-link{
  padding: 0!important;
}
.nav-item:first-of-type{
  border-left: 2px solid #588157;
  padding-left: 20px;
}
.nav-item:last-of-type{
  border-right: none;
}
.nav-item{
  border-right: 2px solid #588157;
  padding-right: 20px;
 
} */
li ul li a {
  transition: all 0.5s ease;
}
.but a{
  color: white;
}
.but a:hover{
  color:#588157;
}
.butonss{
  display: flex;
  
}

.swiper .tetmarsi{
  width: 50%;
  float: right;
  object-fit: fill;
}
.tet{
  background-image:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('./imgs/love.jpg') ; 
  background-size: 50%;
}
.tet1{
  background-image:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('./imgs/17shkurt.jpg') ; 
  background-size: 50%;
}
.tet2{
  background-image:linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('./imgs/8marsi.jpg') ; 
  background-size: 50%;
}

.pertet{
  left: 25%;
  color: black;

}
.pertet p{
  color: black;
}
.pertet img{
  width: 150px;
  display: revert;
}
.pertet h1 span{
  color: black;
}
.pertet a{
  color: black;
}
.pertet a::after{
  background-color: black;
}
.dasma{
  background-image: url('./imgs/wed2.jpg');
}
.ha{
  height: auto;
}
.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-size: 65px;
  letter-spacing: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.waviy span {
  position: relative;
  display: inline-block;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #9c9284;
  color: white;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
  font-weight: bold;
  
  
}
.ha a{
  font-size: 16px;
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.festa{
  padding-top: 30px;
}
.aimg{
  margin-left: 30px;
  outline-offset: -5px;
  height: 450px!important;
}
.bimg {
  outline-offset: -5px;
  height: 450px!important;
}

.active{
  border-bottom: 2px solid #588157;
}
@media screen  and (max-width:991px){
  .pertet img{
    width: 50px;
    height: 50px;
  }
  .waviy{
    font-size: 40px;
  }
  .festa .about-us-imgs{
    display: block;
  }
  .pertet p{
    font-size: 16px;
  }
  .bimg{
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .aimg{
    margin-left: 0;
    margin-top: 0;
  }
}
.tet .pertet h1 span{
  color: #b01736;
}
.tet1 .pertet h1 span{
  color: #263870;
}
.tet2 .pertet h1 span{
  color: #924b02;
}
